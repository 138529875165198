<template>
  <div class="index">
    <div class="header">
      <div style="display: flex;justify-content: center;align-items: center">
        <h2 style="margin-right: 20px;color:var( --td-text-color-primary)">用户管理</h2>
        <t-tooltip content="设置全部用户的权限,请谨慎操作" theme="warning">
          <t-switch v-model="allAuto" @change="set_state_all" :customValue="['1','0']" :label="['开','关']" />
        </t-tooltip>
      </div>
      <t-button theme="primary" @click="add_toast=true,venue_toast_title='添加用户',this.initForm()">
        <template #icon>
          <t-icon name="add"></t-icon>
        </template>
        添加
      </t-button>
    </div>
    <div class="table">
      <t-table :fixed-rows="[0,0]" :loading="table_loading" :data="data" :columns="columns" row-key="id" table-layout="fixed" style="height: calc(100% - 90px);border-bottom: 1px solid var(--td-border-level-2-color)" headerAffixedTop>
        <template #auto="{ col, row }">
          <t-switch v-model="row.auto" @change="set_state(row,e)" :customValue="['1','0']" :label="['开','关']" />
        </template>
        <template #opertion="{row}">
          <t-popconfirm content="确认删除该用户？" theme="danger" @confirm="del_venue(row)">
            <t-button variant="text" theme="danger"> 删除 </t-button>
          </t-popconfirm>
        </template>
      </t-table>
      <div class="pages">
        <t-pagination
            v-model="pages"
            :pageSize="limit"
            :total="total"
            show-jumper
            style="width: 100%;"
            @currentChange="pageChange"
            @page-size-change="pageSizeChange"
        />
      </div>
    </div>
    <t-dialog :header="venue_toast_title" placement="center" :visible="add_toast" @confirm="up" @close="add_toast=false">
      <template #body>
        <t-form :data="formData" :rules="rules" ref="form">
          <t-form-item label="用户名" name="user">
            <t-input placeholder="请输入内容" v-model="formData.user" />
          </t-form-item>
          <t-form-item label="密码" name="pas">
            <t-input placeholder="请输入内容" v-model="formData.pas" />
          </t-form-item>
          <t-form-item label="用户权限" name="auto">
            <t-switch v-model="formData.auto" :customValue="['1','0']" :label="['开','关']" />
          </t-form-item>
        </t-form>
      </template>
    </t-dialog>
    <t-dialog @close="choice_poin_toast=false" @confirm="choice_poin" header="查找地点坐标" placement="center" :visible="choice_poin_toast" width="60%" height="600px">
      <template #body>
        <div style="width: 300px;margin-bottom: 20px;display: flex">
          <t-input placeholder="请输入内容" v-model="address" />
          <t-button :loading="search_address_loading" theme="primary" style="margin-left: 10px;" @click="search_address">搜索地点</t-button>
        </div>
        <div id="container"></div>
      </template>
    </t-dialog>

  </div>
</template>
<script>
import {add_venue, del_venue, edit_venue, get_address_poin, get_venue_id, get_venue_list} from "../../api/venue";
import { shallowRef } from '@vue/reactivity'
import {Icon} from 'tdesign-icons-vue-next'
import AMapLoader from '@amap/amap-jsapi-loader';
import {MessagePlugin} from "tdesign-vue-next";
import { ElUpload } from 'element-plus'
import 'element-plus/dist/index.css'
import {add_user, del_user, get_user_list, set_all_state, set_user_state} from "../../api/user";
export default {
  components:{
    Icon,
    ElUpload
  },
  setup(){
    const map = shallowRef(null);
    return{
      map,
    }
  },
  data(){
    return {
      search_address_loading:false,
      current:1,
      data:[],
      pages:1,
      limit:10,
      total:0,
      table_loading:false,
      allAuto:'0',
      columns:[
        {
          colKey: 'user',
          title: '用户名',
        },
        {
          colKey: 'pas',
          title: '密码',
        },
        {
          colKey: 'auto',
          title:'用户权限',
        },
        {
          colKey: 'opertion',
          title:'操作',
          align:'center',
          width:'250px',
          className: 'row_tr',
        }
      ],
      add_toast:false,
      formData:{
        user:'',
        pas:'',
        auto:'0'
      },
      choice_time:[],
      choice_poin_toast:false,
      address:'',
      markers:[],
      file1:[],
      rules:{
        name:[{ required: true},{min:4},{max:50}],
        type:[{ required: true}],
        work_time:[{ required: true}],
        address_str:[{ required: true}],
        address_poin:[{ required: true}],
        con:[{ required: true}],
        img:[{ required: true}]
      },
      all_auto_toast:false,
      venue_toast_title:'添加用户'
    }
  },
  mounted() {
    this.get_list()
    // this.initMap()
  },
  methods:{
    //设置所有用户
    async set_state_all(){
      let {code,msg} = await set_all_state({auto:this.allAuto})
      if(code===100) MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.data = []
      this.get_list()
    },
    //设置用户的权限
    async set_state({user,auto}){
      let {code,msg} = await set_user_state({user,auto})
      if(code===100) MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.data = []
      this.get_list()
    },
    //初始化地图
    initMap(){
      this.choice_poin_toast = true
      AMapLoader.load({
        key:"1c9d6f1f79687710334ee12f44b89411",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"3D",    //是否为3D地图模式
          zoom:5,           //初始化地图级别
          center:[105.602725,37.076636], //初始化地图中心点位置
        });
        this.map.on('click',(e)=>this.user_set_poin(e))
      }).catch(e=>{
        console.log(e);
      })
    },
    async get_list(){
      this.table_loading = true
      let res = await get_user_list({pages:this.pages,limit:this.limit})
      this.data = [...res.data,...this.data]
      this.table_loading = false
      console.log(this.data)
      this.total = res.count
    },
    pageChange(e){
      this.pages = e
      this.data = []
      this.get_list()
    },
    pageSizeChange(e){
      this.limit = e
      this.data = []
      this.get_list()
    },
    timeChange(e){
      this.formData.work_time.start = e[0]
      this.formData.work_time.end = e[1]
    },
    //设置坐标
    choice_poin(){
      this.choice_poin_toast = false
      console.log('sdafasdf')
    },
    async search_address(){
      if(this.address===''){
        MessagePlugin.warning({content:'请输入详细地址'})
        return
      }
      this.search_address_loading = true
      let data = await get_address_poin({address:this.address})
      this.search_address_loading = false
      this.set_poin(data.data.geocodes[0].location.split(','))
      console.log(data)
    },
    set_poin(poin){
      this.removeMarkers()
      this.formData.address_poin.lat = poin[0]
      this.formData.address_poin.lng = poin[1]
      var marker = new AMap.Marker({
        position: new AMap.LngLat(poin[0], poin[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter(poin);
      this.map.setZoom(16);
    },
    //用户手动设置定位点
    user_set_poin(e){
      this.removeMarkers()
      this.formData.address_poin.lat = e.lnglat.lng
      this.formData.address_poin.lng = e.lnglat.lat
      var marker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.lng,e.lnglat.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter([e.lnglat.lng,e.lnglat.lat]);
    },
    removeMarkers(){
      for(let i of this.markers){
        this.map.remove(i)
      }
      this.markers = []
    },
    upImg(e){
      console.log(e)
      if(this.formData.img===''){
        this.formData.img += e.data
      }else {
        this.formData.img += '|'+e.data
      }
      console.log(this.formData.img)
    },
    del_img(e){
      console.log(e)
      let img = ''
      this.formData.img.split('|').forEach((item,index)=>{
        if(item !== e.url){
          if(index===0){
            img += item
          }else{
            img += '|'+item
          }
        }
      })
      this.formData.img = img
      console.log(this.file1)
      console.log(this.formData.img)
    },
    initForm(){
      this.formData = {
        user:'',
        pas:'',
        auto:'0'
      }
      this.choice_time = []
      this.file1 = []
      this.$refs.form.clearValidate()
    },
    async up(){
      if(await this.$refs.form.validate()!==true){
        MessagePlugin.warning({content:'请填写完整'})
        return
      }
      console.log(this.formData)
      this.$loading(true);
      let data = await add_user(this.formData)
      this.$loading(false)
      if(data.code===100){
        MessagePlugin.success({content:"添加成功！"})
        this.add_toast = false
      }else {
        MessagePlugin.error({content:data.msg})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    async edit_up(){
      this.$loading(true);
      let id = this.formData.id
      delete this.formData.id
      let data = await edit_venue({id,up:this.formData})
      this.$loading(false)
      if(data.code===100){
        MessagePlugin.success({content:"编辑成功！"})
        this.add_toast = false
      }else {
        MessagePlugin.error({content:"编辑失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    //  删除展馆
    async del_venue({user}){
      let {code,msg} = await del_user({user})
      if(code===100) MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.data = []
      this.get_list()
    },
    //  编辑展馆
    async edit({id}){
      this.$loading(true)
      let {data,code,msg} = await get_venue_id({id})
      this.$loading(false)
      this.venue_toast_title = '编辑展馆'
      console.log(data)
      this.formData = data
      this.formData.address_poin = JSON.parse(data.address_poin)
      this.formData.work_time = JSON.parse(data.work_time)
      this.file1 = []
      this.formData.img.split('|').map(item=>{
        this.file1.push({
          lastModified:123045,
          name:'sdaf',
          percent:100,
          raw:{},
          response:{},
          size:10,
          status:'success',
          type:'image',
          url:item
        })
      })

      this.choice_time = [this.formData.work_time.start,this.formData.work_time.end]
      this.add_toast = true
    }
  }
}
</script>

<style scoped>
#container{
  width: 100%;
  height: 600px;
}
.table{
  height: calc(100% - 90px);
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}
.index{
  width: 100%;
  height: 100%;
  background-color: var(--td-bg-color-container);
}
.row_tr{
  height: 40px;
}
.header>h2{
  font-size: 24px;
  color: #383838;
  letter-spacing: 1px;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 8px 12px;
  box-sizing: border-box;
}
.pages{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 8px;
  box-sizing: border-box;
}
</style>

<style>
.t-table th:not([align]), .t-table td:not([align]){
  height: 40px;
}
.index .t-table__body>tr{
  height: 40px;
}
.index .t-time-picker{
  width: 100%;
}
.index .t-pagination__total{
  text-align: left;
}
.index .t-button--variant-base.t-button--theme-primary{
  background-color: #42b983;
  border-color: #42b983;
}
.index .t-button--variant-base.t-button--theme-primary>div>div{
  background-color: #24b07d !important;
}
.index>.t-table__header>tr{
  background-color: #eee;
}
</style>
