import axios from '../utils/request'

export function login(data){
    return axios({
        url:'/login',
        method:'post',
        data
    })
}

export function get_user_list(params){
    return axios({
        url:'/get_user_list',
        method:'get',
        params
    })
}

export function set_user_state(data){
    return axios({
        url:'/set_user_state',
        method:'post',
        data
    })
}

export function del_user(data){
    return axios({
        url:'/del_user',
        method:'post',
        data
    })
}

export function add_user(data){
    return axios({
        url:'/add_user',
        method:'post',
        data
    })
}

export function set_all_state(params){
    return axios({
        url:'/set_all_state',
        method:'get',
        params
    })
}

export function logout(data){
    return axios({
        url:'/logout',
        method:'post',
        data
    })
}


